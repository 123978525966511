<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title> Доступные видео файлы </template>
      <div class="d-flex flex-column">
        <a-form-model
          v-if="false"
          :ref="'field_video'"
          v-model="data"
          :model="[{ name: 'video', title: 'Видео', type: 'images', table_name: 'information', group_id: 3, photo: false }]"
          :errors="errors"
          :config="{ dense: true }"
          @validate="validate"
        />
        <div class="" style="height: 200px">
          <v-card-text :style="{ height: $root.windowSize.contentHeight - 108 + 'px' }" class="pa-0">
            <a-table-f-api ref="table" api="/uploads" :model="modelList" :useQuery="false" :defaults="defaults" @click="getLink($event)" />
          </v-card-text>
        </div>
      </div>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { submitForm, removeEl, genModel } from "../../components/mixings";

export default {
  mixins: [submitForm, removeEl, genModel],
  components: {},
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      data: { video: [] },
      errors: {},
      model1: [],
      modelForm: null,
      m: this.$store.getters["config/get"].models.info,
      modelList: [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "createdon", title: "Дата", type: "datetime", sortable: true, width: 120 },
        { name: "createdby_id", title: "Автор", type: "select", dir: "user", sortable: true, width: 150 },
        { name: "name", title: "Название", type: "string", sortable: true, width: 180 },
        { name: "file", title: "файл", type: "string", sortable: true, width: 240 },
      ],
      api: "/mechti/information",
      removeDialogShow: false,
      showTests: false,
      url: "/uploads",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: {
          "uploads_docs.parent_name": "information",
        },
      },
    };
  },
  computed: {},
  created() {
    // this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    getLink(e) {
      console.log(e);
      const text = `<div class="video-container" style="width: 100%;"><video controls playsinline style="width: 100%; height: auto; display: block; margin: 0 auto" crossorigin="anonymous"><source src="https://api.gk-mechti.ru/api/file/download/${e.row.file}" type="video/mp4" crossorigin="anonymous" /></video></div>`;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$root.$emit("show-info", {
            text: " Скопировано в буфер обмена!",
          });
        })
        .catch((error) => {
          console.error("Не удалось скопировать: ", error);
        });
    },
    validate(e) {
      console.log("validate", e);
      this.$refs.table.updateData();
      this.data.video = [];
    },
    saveTest(e) {
      let data = { id: this.id, test_data: e };
      this.post(data, this.api);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
